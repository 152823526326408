import React from 'react';
import PropTypes from 'prop-types';

import Hero from '../components/Hero';
import Section from '../components/Section';
import Block from '../components/Block';
import { MarkdownContent } from '../components/Content';

const BlockPage = ({ title, heroImage, heroContent, blocks }) => {
  return (
    <>
      <Hero image={heroImage} />
      <Section colorScheme="black">
        <div className="container flex flex-col md:flex-row py-12 gap-x-12">
          <div className="pb-4 md:w-1/3">
            <h2>
              <span className="bg-theme-underline">{title}</span>
            </h2>
          </div>
          <div className="md:w-2/3 text-base">
            {heroContent && <MarkdownContent content={heroContent} />}
          </div>
        </div>
      </Section>
      {blocks &&
        blocks.map((block, index) => (
          <Block
            key={index}
            colorScheme={block.colorScheme}
            overlayLength={block.overlayLength}
            overlaySide={block.overlaySide}
            leftContent={block.left}
            rightContent={block.right}
          />
        ))}
    </>
  );
};

BlockPage.propTypes = {
  title: PropTypes.string.isRequired,
  heroImage: PropTypes.object.isRequired,
  heroContent: PropTypes.string,
  blocks: PropTypes.arrayOf(
    PropTypes.shape({
      colorScheme: PropTypes.string,
      overlaySide: PropTypes.string,
      overlayLength: PropTypes.string,
      leftContent: PropTypes.string,
      rightContent: PropTypes.string,
    })
  ),
};

export default BlockPage;
