import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import { defaultLocale } from '../config/i18n.config';
import { LocaleProvider } from '../context/localeContext';
import Layout from '../components/Layout';
import Section from '../components/Section';
import BlockPage from './BlockPage';
import NewsletterSignupForm from '../components/NewsletterSignupForm';

const MissionPageTemplate = ({ data, pageContext }) => {
  const { frontmatter: page } = data.missionPage;

  return (
    <LocaleProvider locale={pageContext.locale}>
      <Layout>
        <BlockPage
          title={page[pageContext.locale].title || page[defaultLocale].title}
          heroImage={page[defaultLocale].image}
          heroContent={
            page[pageContext.locale].hero || page[defaultLocale].hero
          }
          blocks={page[pageContext.locale].blocks || page[defaultLocale].blocks}
        />
        <Section colorScheme="white">
          <NewsletterSignupForm />
        </Section>
      </Layout>
    </LocaleProvider>
  );
};

MissionPageTemplate.propTypes = {
  data: PropTypes.shape({
    missionPage: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default MissionPageTemplate;

export const pageQuery = graphql`
  query MissionPage($id: String!) {
    missionPage: markdownRemark(id: { eq: $id }) {
      frontmatter {
        en {
          image {
            childImageSharp {
              gatsbyImageData(quality: 90, layout: FULL_WIDTH)
            }
          }
          title
          hero
          blocks {
            colorScheme
            left
            overlayLength
            overlaySide
            right
          }
        }
        de {
          title
          hero
          blocks {
            colorScheme
            left
            overlayLength
            overlaySide
            right
          }
        }
      }
    }
  }
`;
