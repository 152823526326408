import React from 'react';
import PropTypes from 'prop-types';

import Section from '../components/Section';
import { MarkdownContent, ImgMarkdownExpression } from '../components/Content';

function BlockHalf({ content, reverseLayout }) {
  const imgArgs = content?.match(ImgMarkdownExpression);
  if (imgArgs) {
    return (
      <div
        className={`hidden md:block absolute w-1/2 top-0 bottom-0 bg-cover ${
          reverseLayout ? 'left-0 bg-left' : 'right-0 bg-right'
        }`}
        style={{
          backgroundImage: `url(${imgArgs[2]})`,
        }}
      ></div>
    );
  } else {
    return (
      <div
        className={`pb-12 md:w-1/2 text-base ${
          reverseLayout ? 'md:pl-12' : 'md:pr-12'
        }`}
      >
        <MarkdownContent content={content} />
      </div>
    );
  }
}

function Block({
  colorScheme,
  overlaySide,
  overlayLength,
  leftContent,
  rightContent,
}) {
  const reverseLayout = !!leftContent?.match(ImgMarkdownExpression);

  return (
    <Section
      colorScheme={colorScheme}
      overlayLength={overlayLength}
      overlaySide={overlaySide}
    >
      <div
        className={`container md:flex pt-12 pb-6 ${
          reverseLayout ? 'justify-end' : ''
        }`}
      >
        <BlockHalf content={leftContent} reverseLayout={reverseLayout} />
        <BlockHalf content={rightContent} reverseLayout={reverseLayout} />
      </div>
    </Section>
  );
}

Block.propTypes = {
  colorScheme: PropTypes.string,
  overlaySide: PropTypes.string,
  overlayLength: PropTypes.string,
  leftContent: PropTypes.string.isRequired,
  rightContent: PropTypes.string,
};

export default Block;
